export let descriptionAbout = {
  part1: [
    "CREDO-edu is our way to engage students into real scientific research.",
    "CREDO-edu to program angażujący uczniów w nasze badania naukowe.",
  ],
  part2: [
    "We are preparing materials for teachers for all year long school course which lead kids from the simplest physics phenomena to ongoing researches concerned on cosmic rays. During the program students will carry out measurements using smartphones, science-tailored app and following educators' handbook. Thus they are dealing with simple analysis of raw scientific data.",
    "W ramach incjatywy skierowanej do szkół i nauczycieli przygotowujemy kompleksowe zestawy materiałów całorocznego koła naukowego. Będą one prowadzić od najprostszych fizycznych zjawisk do zapoznania uczniów z promieniowaniem kosmicznym. W trakcie trwania programu uczniowie wykonają pomiary przy użyciu smartfonów i naszej dedykowanej aplikacji według ustalonego harmonogramu, a także nauczą się podstaw analizy danych pomiarowych. ",
  ],
  part3: [
    "Their will have also a posibility to propose their own measurement scheme which will be implemented by all participants of the CREDO-edu program!",
    "Chętni mogą zaproponować własne eksperymenty. Zrealizujemy je wspólnie ze wszystkimi szkołami uczestniczącymi w programie!",
  ],
  part4: [
    "All you need to paricipate in the programme you can find at home and simple physics lab. This makes CREDO-edu afordable for every school and anywhere in the world.",
    "Wszystko, czego potrzeba, aby wziąć udział w programie mozna znaleźć w domu i podstawowym laboratorium fizycznym. To sprawia, że CREDO-edu jest dostępne dla każdej szkoły i w każdym miejscu na świecie.",
  ],
};
export let descriptionContact = {
  part1: [
    "Our team is open to working with any institution, scientist, teacher, individual who would like to join the CREDO (or CREDO-Edu) project.",
    "Nasz zespół jest otwarty na współpracę z każdą instytucją, naukowcem, nauczycielem, osobą prywatną która chciałaby dołączyć do projektu CREDO (lub CREDO-Edu).",
  ],
  part2: [
    "If you need more information or would like to join us please contact us by writing to our Head of Task: ",
    "Jeśli potrzebujesz więcej informacji lub chciałbyś do nas dołączyć, skontaktuj się z nami, pisząc do lidera grupy: ",
  ],
};

export let descriptionHeader = {
  part1: ["About", "O projekcie"],
  part2: ["People", "Zespół"],
  part3: ["Activites", "Aktywności"],
  part4: ["Materials", "Materiały"],
  part5: ["Contact", "Kontakt"],
};

export let descriptionPeople = {
  title: ["Principal Persons", "Skład zespołu"],
  lider: ["Head of task", "Lider zespołu"],
  uw: ["University of Warsaw", "Uniwersytet Warszawski"],
  dr: ["Ph.D.", "Dr"],
  mgr: ["Msc", "mgr"],
};
