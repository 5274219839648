<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO institutional members: Poland
  </h1>
  <a href="#/credo-institutional-members"
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      style="vertical-align:bottom;"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
      />
    </svg>Back</a
  >
  <div class="flex flex-wrap -m-4">
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/cyfronet_logo.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Academic Computer Centre CYFRONET AGH
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.cyfronet.pl/en/4421,main.html"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/agh.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            AGH University of Krakow
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.agh.edu.pl/en/"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/almukantarat.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Almukantarat Astronomy Club
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.almukantarat.pl/en/"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/astrotectonic.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Astrotectonic Sp. z o.o.
          </h2>
          <a
            style="color: #0000ff;"
            href="https://astrotectonic.com"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/LogoNiebieskie1.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Children University of Silesia in Katowice
          </h2>
          <a
            style="color: #0000ff;"
            href="https://dzieci.us.edu.pl"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/CNK.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Copernicus Science Centre
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.kopernik.org.pl/en"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/pk.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Cracow University of Technology
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.pk.edu.pl/index.php?lang=en"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/credo-universe.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            CREDO-UNIVERSE Association
          </h2>
          <a
            style="color: #0000ff;"
            href="http://credo-universe.eu"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/CNExperyment.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Experyment Science Centre in Gdynia
          </h2>
          <a
            style="color: #0000ff;"
            href="https://experyment.gdynia.pl/en/"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/logo_wfis_ul_h_en_rgb.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Faculty of Physics and Applied Computer Science,<br />
            University of Lodz
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.wfis.uni.lodz.pl"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/uj.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Jagiellonian University in Krakow
          </h2>
          <a
            style="color: #0000ff;"
            href="https://en.uj.edu.pl/en"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/ncbj_poziom_pl_rgb.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            National Center for Nuclear Research
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.ncbj.gov.pl/en"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/wat.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Military University of Technology
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.wojsko-polskie.pl/wat/"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/logoUP_en-1.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            University of the National Education Commission, Krakow
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.up.krakow.pl/en/"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>

    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/ptma.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Polish Society of Amateur Astronomers
          </h2>
          <a
            style="color: #0000ff;"
            href="https://ptma.pl"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/Szkoła_z_Charakterem.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            School with Character
          </h2>
          <a
            style="color: #0000ff;"
            href="https://szkolazcharakterem.pl/"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/rzezawa.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            St Jadwiga the Queen Primary School in Rzezawa
          </h2>
          <a
            style="color: #0000ff;"
            href="https://sprzezawa.szkolnastrona.pl/index.php"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/logo_ifj.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            The Henryk Niewodniczański Institute of Nuclear Physics<br />Polish
            Academy of Sciences
          </h2>
          <a
            style="color: #0000ff;"
            href="https://www.ifj.edu.pl/en/index.php"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/us.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            University of Silesia in Katowice
          </h2>
          <a
            style="color: #0000ff;"
            href="http://english.us.edu.pl"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/logo-pi.png"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            University of Warsaw
          </h2>
          <a
            style="color: #0000ff;"
            href="https://en.uw.edu.pl"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="object-hover w-full"
          src="./images/flags/members/swiatowy_kongres_polakow.jpg"
        />
        <div class="w-full mt-4">
          <h2 class="title-font font-medium text-lg text-gray-900">
            World Polish Congress
          </h2>
          <a
            style="color: #0000ff;"
            href="https://swiatowykongrespolakow.pl"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
